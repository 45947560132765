import { RoundedContainer } from '@components/RoundedContainer';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { Input } from '@elements/Input';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { useQueryParams } from '@hooks/use-query-params';
import { KoraApi } from '@services/api';
import { useDemoSignup } from '@services/user/use-demo-signup';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

export const LandingPage = () => {
  const { t } = useTranslation('landing');
  const isMobile = useIsMobile();
  const { isValid, submit, setEmail, isLoading } = useDemoSignup();
  const { referral } = useQueryParams();
  useEffect(() => {
    if (referral) {
      const getReferral = async () => {
        await KoraApi.get(`/referrals/${referral}/open`);
      };
      getReferral();
    }
  }, [referral]);
  return (
    <Container className="mt-[-1%] flex h-full flex-col items-center justify-center gap-4">
      <RoundedContainer className="flex w-full max-w-[730px] flex-col items-center p-10">
        <div className="mb-3 text-center">
          <Typography variant="h1" className="mb-8 text-dark">
            {t('title')}
          </Typography>
          <Typography
            variant="h3"
            className="mb-10 !text-[1.5rem] leading-normal text-dark"
          >
            {t('subtitle')}
          </Typography>
        </div>
        <div className="w-[80%]">
          <Typography variant="caption" className="mb-1 text-primary">
            {t('get_notified')}
          </Typography>
          <div className={`flex gap-0 ${isMobile && 'flex-col'}`}>
            <Input
              type="email"
              onChange={e => setEmail(e.target.value)}
              placeholder={t('email_placeholder')}
              className={`${!isMobile && 'rounded-[8px_0px_0px_8px]'}`}
            />
            <Button
              disabled={!isValid || isLoading}
              variant="secondary"
              className={`min-w-[150px] ${isMobile ? 'mt-3' : '!rounded-[0px_8px_8px_0px]'}`}
              onClick={submit}
            >
              {isLoading ? (
                <ClipLoader size={16} className="me-2" color="#fff" />
              ) : (
                t('btn_notify')
              )}
            </Button>
          </div>
        </div>
      </RoundedContainer>
      <div className="w-full max-w-[730px] flex-col items-center rounded-[20px] bg-secondary p-6">
        <Typography className="text-2xl leading-normal text-white">
          <Trans>{t('description_1')}</Trans>
        </Typography>
      </div>
      <RoundedContainer className="flex w-full max-w-[730px] flex-col items-center px-10">
        <Typography className="text-normal leading-normal text-dark">
          <Trans>{t('description_2')}</Trans>
        </Typography>
      </RoundedContainer>
      <Typography variant="caption" className="mb-1 text-dark">
        * We're GDPR compliant
      </Typography>
    </Container>
  );
};
